<template>
  <div class="container-fluid" id="hero" data-aos="zoom-in" data-aos-duration="3000">
      <div class="row justify-content-center align-items-center">
          <div class="col-md-7 text-element" >
              <h2>
                  AfCFTA Link provides enterprise level features in the AfCFTA App, thus adding advanced functionality to AfCFTA App’s array of tools for governments, businesses and everyone aiming to grow their prosperity through AfCFTA.
              </h2>
              <a :href="`${service()}/accounts/register`">GET STARTED</a>
          </div>
      </div>
  </div>
</template>

<script>
import service from '../helpers/url'

export default {
    methods: {
        service() {
            return service()
        }
    }
}
</script>

<style scoped>
#hero {
    width:100vw;
    margin-top: 96px;
    background-image: url('../assets/1.jpg');
    background-position: center center;
    background-size: cover;
    height: calc(100vh - 96px);
    color:#fff;
    text-align: center;
}
a{
    background-color: #fff;
    color: #bf2a38;
    font-weight: bolder;
    padding:20px;
    border:1px solid #fff;
    margin-top:20px;
    margin-bottom: 20px;
    transition: all 0.4s;
}
a:hover{
  color:#fff;
  background-color: #ac414b;  
}
.text-element{
    margin-top:10%;
}
h2{
    margin-bottom:50px;
    text-align: center;
    font-weight: 700;
    line-height: 1.4em;
}
@media screen and (max-width:720px) {
    .text-element{
        margin-top: 30%;
    }   
}
@media screen and (max-width:676px) {
     .text-element{
        margin-top: 10%;
    }  
    #hero{
        height: 100%;
        padding-bottom: 5em;
    }
    h2{
        font-weight: 500px;
        font-size: 1.8em;
    }    
}
</style>