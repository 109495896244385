<template>
  <div id="contact">
    <div class="d-flex justify-content-center">
      <div class="form-container">
        <div class="row justify-content-center">
          <div class="col-12">
            <h2>Contact Us</h2>
            <form>
              <div class="form-group pt-3">
                <input type="text" class="form-control" placeholder="Name" required v-model="name"/>
              </div>
              <div class="form-group pt-3">
                <input type="text" class="form-control" placeholder="Email Address" required v-model="email" />
              </div>
              <div class="form-group pt-3">
                <input type="text" class="form-control" placeholder="Organisation" required v-model="organization" />
              </div>
              <div class="form-group pt-3">
                <textarea rows="3" type="text" class="form-control" placeholder="Message/Questions" v-model="message"> </textarea>
              </div>
              <div class="form-group pt-3">
                <button class="btn btn-outline-light px-4 py-2" type="submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
 data() {
          return {
            name:null,
            email:null,
            organization:null,
            message:null
          }
        },
      methods: {
          contactUs() {
             const data = {
                 name:this.name,
                 organization:this.organisation,
                 message:this.message,
                 email:this.email
             }
             this.$store.dispatch('contactUs', data)
                .then(response => {
                    if(response) {
                        this.toast({
                            message:'Your message has been sent successfully, Our team will get back to you soon.',
                            type:'success'
                        })
                    }
                }).catch(err => {
                    if(err?.response?.data == undefined) {
                        this.toast({
                            message:'Network error. Check your network connection and try again.'
                        })
                    }
                })
         },
        }
}
</script>

<style scoped>
/* CONTACT */
#contact {
	background-image: url("../assets/contact.jpg");
	width: 100%;
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
h2{
  color:#fff;
  padding:20px 0;
}
.form-container{
  padding:70px 0;
  width:60%;
}

@media screen and (max-width:500px) {
  .form-container{
    width:80%;
  }
}
</style>