let service = () =>{
    let service
    if(process.env.NODE_ENV === 'development') {
        service = `service=http://localhost:8081`
    } else {
        // stagging service 
        //  service = `service=https://test.afcfta.link/` 
        service = `service=https://afcfta.link/`
    }
    return service
}

export default service;