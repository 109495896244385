<template>
  <div class="home">
    <NavBar />
    <HeroComponent />
    <GridComponent/>
     <About />
    <Contact />
    <FinalFooter />
  </div>
</template>

<script>
import NavBar from '../components/NavBar'
import HeroComponent from '../components/HeroComponent'
import GridComponent from '../components/gridComponent'
import Contact from '../components/Contact.vue'
import FinalFooter from '../components/FinalFooter'
import About from '../components/About'
export default {
  name: 'Home',
  components: {
    NavBar,
    HeroComponent,
    GridComponent,
    Contact,
    About,
    FinalFooter
  }
}
</script>
