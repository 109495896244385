import axios from 'axios'
import url from '../../helpers/url'
import Vue from 'vue'
import VueSessionStorage from 'vue-sessionstorage'

export const state = {
    sentCertifications:[],
    receivedCeritfications:[]
}

export const actions =  {
    addCertificationOfOrigin({commit},data){
        return new Promise((resolve,reject)=>{
            axios.post(`${url()}/api/link/sent-certificate-of-origin/${data.id}`,data.certification, {
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }
            })
            .then(response =>{
                if(response.status === 200){
                    commit("ADD_SENT_CERTIFICATION",response.data)
                }
                resolve(response)
            }).catch(err=>{
                reject(err)
            })
        })

    },
    // fetchUserProducts({commit},id){
    //     return new Promise((resolve,reject)=>{
    //         axios.get(`${url()}/link/entity-products/${id}`,{
    //             headers: {
    //                 'Authorization':`${Vue.prototype.$session.get('entity')}`
    //             }
    //         }).then(response=>{
    //             commit('SET_USER_PRODUCTS',response.data)
    //             resolve(response)
    //         }).catch(err=>{
    //             reject(err)
    //         })
    //     })
    // }

}