<template>
  <footer>
  <div class="container">
    <div class="row" style="margin:80px 0">
      <div class="col-md-8 footer-column">
          <div class="row">
            <div class="col-12">
                 <h2 class="text-danger mb-4">About AfCFTA</h2>
            </div>
              <div class="col-md-6">
                   <p  class="text-white text-justify">
                  AfCFTA was created by the African Continental Free Trade Agreement among 54 of the 55 African Union nations. The free-trade area is the largest in the world in terms of the number of participating countries since the formation of the World Trade Organization. Accra, Ghana serves as the Secretariat of AFCFTA.
                </p>
              </div>
              <div class="col-md-6">
                <p class="text-white text-justify">
                  Once fully operational, AfCFTA should considerably expand intra-Africa trade whilst helping the continent move up the value chain in multiple industries, helping achieve the vision of Agenda 2063 of a united, prosperous, continent relating on equal footing with its peers on the global stage. 
                  </p>
              </div>
          </div>
      </div>
      <div class="col-md-4 footer-column">
        <h2 class="text-danger mb-4">
          Contact & Support
        </h2>
        <a href="#contact">
          feedback
        </a>
      </div>
    </div> 
  </div>
</footer>
</template>

<script>
export default{
  name:'about'
}
</script>

<style lang="scss" scoped>
$link-light-grey: #6c757d;
$primary-orange: #fec503; 
$footer-background-light-grey: #212529;
$footer-background-grey: #1a1d20;
$social-buttons-circle-dark-grey: #1a1d20;


a {
  color: $link-light-grey;
}
a:hover {
  color: $primary-orange;
  text-decoration: none;
}

 


footer {
  padding: 2rem 0;
  background-color: #212529;

}

.footer-column:not(:first-child) {
    padding-top: 2rem;
    @media (min-width: 768px) {
      padding-top: 0rem;
    }
  }

.footer-column {
  text-align: center;
  .nav-item {
    .nav-link {
      padding: 0.1rem 0;
    }
    span.nav-link {
      color: $link-light-grey;
    }
    span.footer-title {
      font-size: 14px;
      font-weight: 700;
      color: #fff;
      text-transform: uppercase;
    }
    .fas {
      margin-right: 0.5rem;
    }
  }
  ul {
    display: inline-block;
    @media (min-width: 768px) {
      text-align: left;
    }
  }
}

ul.social-buttons {
  margin-bottom: 0;
}

ul.social-buttons li a:active,
ul.social-buttons li a:focus,
ul.social-buttons li a:hover {
  background-color: $primary-orange;
}

ul.social-buttons li a {
  font-size: 20px;
  line-height: 40px;
  display: block;
  width: 40px;
  height: 40px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  color: #fff;
  border-radius: 100%;
  outline: 0;
  background-color: $footer-background-grey;
}

footer .quick-links {
  font-size: 90%;
  line-height: 40px;
  margin-bottom: 0;
  text-transform: none;
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.copyright {
  color: white;
}

.fa-ellipsis-h {
  color: white;
  padding: 2rem 0;
}

</style>