<template>
    <div class="container main-container " id="about">
       <div class="row justify-content-start">
           <div class="col-12">
               <h2>
                   ABOUT AfCFTA LINK
                     
               </h2>
             <hr>
           </div>
       </div>
       <div class="row no-gutters mt-4">
           <div class="col-md-3 mr-0">
               <img class="img-fluid" src="../assets/4.jpg" alt="">
           </div>
           <div class="col-md-9 ml-0 about-text">
               <p>
                   The <strong>AfCFTA App</strong> presents a basic level of functions for most organizations to take 
                   advantage of AfCFTA and grow their business or extend their activities across Africa. For organisations with more complex needs, there is <strong>AfCFTA Link</strong>.
                    Designed to enable interoperability among the business/operational processes of governments,
                     business and other entities, whilst enabling easy outreach to the 1.2 billion individual 
                     African citizens and consumers who are AfCFTA’s biggest beneficiaries. Because every entity, 
                     individual or actor in the AfCFTA ecosystem gets a free <strong>AfCFTA number </strong> in what should become the world’s largest trade registry, everyone is a just click away in Africa.
               </p>
           </div>
       </div>
       <div class="row" id="psd">
        <div class="row justify-content-start">
           <div class="col-12 psd">
               <h2>
                 AfCFTA PRIVATE SECTOR DASHBOARD
                     
               </h2>
             <hr>
           </div>
       </div>
       <div class="col-12 mt-4">
           <div class="row">
              <div class="col-md-3">
                  <img src="../assets/12.jpg" alt="" class="img-fluid">
              </div>
              <div class="col-md-6 about-text">
                  <p>
                      The AfCFTA <strong>Private Sector Dashboard</strong> (PSD) Aggregates value chain information from business associations,
                      chambers of commerce and industry associations and generates insights for key actors in the AfCFTA ecosystem. It aims to power
                      interfaces and analytics modules in various continental economic data systsms, including the African Trade Observatory.

                  </p>
              </div>
              <div class="col-md-3">
                  <img src="../assets/rsz_1afcfta_link_6.jpg" class="img-fluid" alt="">
              </div>
           </div>
       </div>
       </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style  scoped>
.psd{
    margin: 9em 0 0;
}
.main-container{
    margin-bottom: 8em;
    padding-top:8em;
}
.image-grid img {
   margin-bottom: 1rem;
}
h2{
    color:#000;
    font-weight: 800;
    font-size: 3em;

}
hr{
    height:5px;
    width:200px;
    background:#000;
    float:left;
}
.about-text{
    background: #ba2428;
    color:#fff;
    border:1px solid #ba2428;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
}
.about-text p{
    padding: 1em;
}
</style>