import Vue from 'vue'
import Vuex from 'vuex'
import  * as auth from './module/auth'
import * as product from './module/product'
import * as certification from './module/certifications'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    product,
    certification
  }
})
