<template>
  <div id="footer">
    <div class="container-fluid top_footer">
      <div class="row justify-content-center">
        <div class="col-12 top_footer_content">
          <div class="row justify-content-center align-items-center">
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 log-wrapper">
              <div class="row justify-content-center align-items-center logo-container">
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-6">
                  <img class="img-fluid" src="../assets/afcft.jpg" alt />
                </div>
                <div class="col-lg-6 col-md-8 col-sm-5 col-xs-6">
                  <p>In partnership with the AfCFTA Secretariat</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 log-wrapper">
              <div class="row justify-content-center align-items-center logo-container">
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-6">
                  <img class="img-fluid" src="../assets/8B.png" alt />
                </div>
                <div class="col-lg-6 col-md-8 col-sm-5 col-xs-6 align-self-center">
                  <p>
                    Affiliated with
                    <a href="https://sankoree.com" target="_blank">the Sankoree Institute</a>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 log-wrapper">
              <div class="row justify-cotent-center align-items-center logo-container">
                <div class="col-lg-4 col-md-6 col-sm-5 col-xs-5 align-self-center">
                  <img class="img-fluid" src="../assets/B.png" alt />
                </div>
                <div class="col-lg-6 col-md-6 col-sm-5 col-xs-6 align-self-center">
                  <p>In association with AU Open Corridors Initiative</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid bottom_footer">
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="row justify-content-center">
            <div class="col-md-8 align-self-center my-4">
              <p class="text-center">AfCFTA LINK &copy; 2020. All rights reserved</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    name:'FinalFooter'
}
</script>
 <style scoped>
 .bottom_footer{
     background-color: #ba2428;
 }
 .text{
     color:#fff;
 }
 .top_footer{
     background-color:#1f1f1e;
     padding-top:40px;
     padding-bottom: 40px;
 }
 .top_footer a{
   text-decoration: underline;
   color:#fff;
 }
 p{
   color:#fff;
 }
 .log-wrapper {
   margin-top: 50px;
   margin-bottom:50px;
 }
 @media screen and (max-width:500px) {
     .text{
         font-size: .8em;
     }
 }
 @media screen and (max-width:767px){
    .top_footer img{
      height: 100px;
    }
    .top_footer_content{
     padding-right: 150px;
     padding-left: 150px;
    }
 };
 @media screen and (max-width:575px) {
   .logo-container{
     display:flex;
     flex-direction: row;
     justify-content: center;
     align-items: center;
   }
   .top_footer_content{
      width: 80%;
      margin: 0 auto;
      padding-right:100px;
      padding-left: 100px;
    }
    .log-wrapper {
      margin-top: 10px;
      margin-bottom:10px;
    }
    .log-wrapper p{
      font-size: .9em;
    }
    
 }
 </style>